// Feathers-Socket.IO Client
import auth from "@feathersjs/authentication-client";
import feathers from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import { discard, iff } from "feathers-hooks-common";
import feathersVuex from "feathers-vuex";
import io from "socket.io-client";

import paramsForServer from "@/store/hooks/params-for-server.js";

const socket = io(process.env.VUE_APP_API_URL, {
  transports: ["websocket"]
});

const feathersClient = feathers()
  .configure(socketio(socket, { timeout: 90000 }))
  .configure(
    auth({
      storageKey: "tpm-jwt",
      storage: window.localStorage
    })
  )
  .hooks({
    before: {
      all: [
        iff(
          (context) => ["create", "update", "patch"].includes(context.method),
          discard("__id", "__isTemp")
        ),
        paramsForServer("$graphql", "$queryType", "$tsquery")
      ]
    }
  });

export default feathersClient;

// Setting up feathers-vuex
const { makeServicePlugin, makeAuthPlugin, BaseModel, models, FeathersVuex } =
  feathersVuex(feathersClient, {
    serverAlias: "api",
    whitelist: ["$regex", "$options", "$graphql", "$tsquery", "$iLike"],
    enableEvents: true
  });

export { makeServicePlugin, makeAuthPlugin, BaseModel, models, FeathersVuex };
