const apps = [
  //
  //* ——— Project ——————————————————
  //
  {
    path: "/apps/project/list",
    name: "apps-project-list",
    component: () =>
      import("@/views/apps/project/project-list/ProjectList.vue"),
    meta: {
      requiresAuth: true,
      layout: "content"
    }
  },
  {
    path: "/apps/project/add/",
    name: "apps-project-add",
    component: () => import("@/views/apps/project/project-add/ProjectAdd.vue"),
    meta: {
      requiresAuth: true,
      layout: "content"
    }
  },
  {
    path: "/apps/project/edit/:project",
    name: "apps-project-edit",
    component: () =>
      import("@/views/apps/project/project-edit/ProjectEdit.vue"),
    meta: {
      requiresAuth: true,
      layout: "content"
    }
  },
  {
    // We're calling it `project` here in the URL
    // but we're really sending the `fulcrum_id` to perform the
    // GET request.
    path: "/apps/project/view/:project",
    name: "apps-project-view",
    component: () =>
      import("@/views/apps/project/project-view/ProjectView.vue"),
    meta: {
      requiresAuth: true,
      layout: "content"
    }
  },
  //
  //* ——— Contract ——————————————————
  //
  {
    path: "/apps/contract/list",
    name: "apps-contract-list",
    component: () =>
      import("@/views/apps/contract/contract-list/ContractList.vue"),
    meta: {
      requiresAuth: true,
      layout: "content"
    }
  },
  {
    path: "/apps/contract/add/",
    name: "apps-contract-add",
    component: () =>
      import("@/views/apps/contract/contract-add/ContractAdd.vue"),
    meta: {
      requiresAuth: true,
      layout: "content"
    }
  },
  //
  //* ——— Construction Record ——————————————————
  //
  {
    // We're calling it `permit` here in the URL
    // but we're really sending the `fulcrum_id` to perform the
    // GET request.
    path: "/apps/project/:project/construction-record/view/:record",
    name: "apps-construction-record-view",
    component: () =>
      import(
        "@/views/apps/construction-record/construction-record-view/ConstructionRecordView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "content"
    }
  },
  {
    path: "/apps/project/:project/construction-record/add/",
    name: "apps-construction-record-add",
    component: () =>
      import(
        "@/views/apps/construction-record/construction-record-add/ConstructionRecordAdd.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "content"
    }
  },
  {
    path: "/apps/project/:project/construction-record/:record/short-list-items/list",
    name: "apps-estimate-items",
    component: () =>
      import(
        "@/views/apps/construction-record/construction-record-edit/ViewShortListItems.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "blank"
    }
  },
  {
    path: "/apps/project/:project/construction-record/edit/:record",
    name: "apps-construction-record-edit",
    component: () =>
      import(
        "@/views/apps/construction-record/construction-record-edit/ConstructionRecordEdit.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "content"
    }
  },
  {
    path: "/apps/project/:project/construction-record/preview/:record",
    name: "apps-construction-record-preview",
    component: () =>
      import(
        "@/views/apps/construction-record/construction-record-preview/ConstructionRecordPreview.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: "content"
    }
  },
  //
  //* ——— Daily Log (IDR) ——————————————————
  //
  {
    path: "/apps/idr/list",
    name: "apps-idr-list",
    component: () =>
      import("@/views/apps/daily-log/daily-log-list/DailyLogList.vue"),
    meta: {
      requiresAuth: true,
      layout: "content"
    }
  },
  {
    // We're calling it `idr` here in the URL
    // but we're really sending the `fulcrum_id` to perform the
    // GET request.
    path: "/apps/project/:project/construction-record/:record/idr/view/:idr",
    //path: "/apps/idr/view/:idr",
    name: "apps-daily-log-view",
    component: () =>
      import("@/views/apps/daily-log/daily-log-view/DailyLogView.vue"),
    meta: {
      requiresAuth: true,
      layout: "content"
    }
  },
  //
  //* ——— User ——————————————————
  //
  {
    path: "/apps/user/list",
    name: "apps-user-list",
    component: () => import("@/views/apps/user/user-list/UserList.vue"),
    meta: {
      requiresAuth: true,
      layout: "content"
    }
  },
  {
    path: "/apps/user/view/:id",
    name: "apps-user-view",
    component: () => import("@/views/apps/user/user-view/UserView.vue"),
    meta: { requiresAuth: true, layout: "content" }
  },
  {
    path: "/apps/user/account-settings/view/:id",
    name: "apps-user-account-settings",
    component: () =>
      import("@/views/apps/user/account-settings/AccountSettings.vue"),
    meta: {
      requiresAuth: true,
      layout: "content"
    }
  }
];

export default apps;
