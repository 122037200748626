import Vue from "vue";
import VueRouter from "vue-router";

import { snackbar } from "@/components/base/snackbar/useSnackbar";

import { store } from "../store";
import account from "./account";
import apps from "./apps";
import dashboard from "./dashboard";
import pages from "./pages";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "dashboard-analytics" }
  },
  ...account,
  ...dashboard,
  ...apps,
  ...pages
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  // Start the route progress bar.
  //NProgress.start();
  const isLoggedIn = store.getters["auth/isAuthenticated"];
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !isLoggedIn) {
    store
      .dispatch("auth/authenticate")
      .then(() => {
        next();
      })
      .catch((error) => {
        snackbar({
          text: error.message,
          color: "error"
        });
        return next("/account/login");
      });
  } else {
    next();
  }
});

// router.afterEach(() => {
//   // Complete the animation of the route progress bar.
//   NProgress.done();
// });
// Allow user to access Signup Page if email and
// token exist in url and if the token hasn't
// expired

// eslint-disable-next-line no-unused-vars
const verifyToken = function (to, from, next) {
  const email = to.query.email;
  const token = to.query.token;

  if (token && email) {
    store
      .dispatch("authManagement/create", {
        action: "verifySignupLong",
        value: token
      })
      .then(() => {
        const message = {
          type: "success",
          message: "Your email has been verified. Thank you!"
        };
        store.dispatch("snackbar/showSnackbar", message, {
          root: true
        });
        next();
      })
      .catch((error) => {
        const message = {
          type: "error",
          message: error.message
        };
        store.dispatch("snackbar/showSnackbar", message, {
          root: true
        });
        return next({ name: "401" });
      });
  } else {
    // token is not in url
    // prevent access to Signup page
    return next({ name: "401" });
  }
};
// Allow user to access Security Question page if email and
// token exist in url and if the token hasn't
// expired
// eslint-disable-next-line no-unused-vars
const verifyResetToken = function (to, from, next) {
  const id = to.query.id;
  const token = to.query.token;

  if (id && token) {
    store
      .dispatch("pwdresetrequests/find", {
        query: {
          user_id: id,
          token: token,
          status: {
            $eq: "Pending",
            $ne: "Fulfilled"
          },
          expiration: {
            $gte: new Date().getTime()
          }
        }
      })
      .then((resetReq) => {
        if (resetReq.data.length > 0) {
          next();
        } else {
          // zero records were returned where the datetime
          // is greater than or equal to today's datetime
          // and/or the status is not equal to 'Pending' or
          // it is equal to 'Fulfilled'
          // redirect to Error page
          return next({ name: "401" });
        }
      })
      .catch((error) => {
        const message = {
          type: "error",
          message: error.message
        };
        store.dispatch("snackbar/showSnackbar", message, {
          root: true
        });
        return next({ name: "401" });
      });
  } else {
    // email and token are not in url
    // prevent access to Signup page
    return next({ name: "401" });
  }
};

export default router;
