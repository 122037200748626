import "@/plugins/vue-composition-api";
import "@/styles/styles.scss";
import "./vee-validate";

import Vue from "vue";
import VueApexCharts from "vue-apexcharts";

import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import { store } from "./store";

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
