<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${appSkinVariant}`"
  >
    <transition :name="appRouteTransition" mode="out-in" appear>
      <router-view></router-view>
    </transition>
    <app-snackbar />
  </component>
</template>

<script>
  // eslint-disable-next-line object-curly-newline
  import { computed } from "@vue/composition-api";

  import useAppConfig from "@/components/base/app-config/useAppConfig";
  import { useLayout } from "@/components/base/layouts/composable/useLayout";
  // Snackbar
  import AppSnackbar from "@/components/base/snackbar/AppSnackbar.vue";
  import { useRouter } from "@/helpers";
  // Dynamic vh
  import useDynamicVh from "@/helpers/useDynamicVh";
  import LayoutBlank from "@/layouts/blank/LayoutBlank.vue";
  // Layouts
  import LayoutContentVerticalNav from "@/layouts/content/LayoutContentVerticalNav.vue";

  export default {
    components: {
      LayoutContentVerticalNav,
      LayoutBlank,
      AppSnackbar
    },
    setup() {
      const { route } = useRouter();
      const { appContentLayoutNav, appSkinVariant, appRouteTransition } =
        useAppConfig();

      const { handleBreakpointLayoutSwitch } = useLayout();
      handleBreakpointLayoutSwitch();

      const resolveLayoutVariant = computed(() => {
        if (route.value.meta.layout === "blank") return "layout-blank";
        if (route.value.meta.layout === "content")
          return `layout-content-${appContentLayoutNav.value}-nav`;

        return null;
      });

      useDynamicVh();

      return {
        resolveLayoutVariant,
        appSkinVariant,
        appRouteTransition
      };
    }
  };
</script>
