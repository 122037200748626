<script>
  import { defineComponent } from "@vue/composition-api";

  import { snackbarState } from "./useSnackbar";

  export default defineComponent({
    name: "AppSnackbar",
    setup() {
      return {
        snackbarState
      };
    }
  });
</script>

<template>
  <v-snackbar
    v-model="snackbarState.show"
    :color="snackbarState.color"
    :max-width="snackbarState.maxWidth"
    :timeout="snackbarState.timeout"
    :dark="snackbarState.dark"
    :light="snackbarState.light"
    :multi-line="snackbarState.multiLine"
    app
    center
    bottom
    vertical
  >
    <span class="font-weight-semibold">{{ snackbarState.text }}</span>
    <template v-if="snackbarState.timeout < 1" v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="snackbarState.show = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>
