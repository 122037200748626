import {
  mdiAccountHardHat,
  mdiBriefcaseVariantOutline,
  mdiFileDocumentEditOutline
} from "@mdi/js";

export default [
  {
    subheader: "CONSTRUCTION"
  },
  {
    title: "Projects",
    icon: mdiBriefcaseVariantOutline,
    children: [
      /*{
        title: "Create New Project",
        to: "apps-project-add"
      },*/
      {
        title: "Project List",
        to: "apps-project-list"
      }
    ]
  },
  {
    title: "Contracts",
    icon: mdiFileDocumentEditOutline,
    children: [
      /*{
        title: "Add New Contract",
        to: "apps-contract-add"
      },*/
      {
        title: "Contract List",
        to: "apps-contract-list"
      }
    ]
  },
  {
    title: "IDRs",
    icon: mdiAccountHardHat,
    children: [
      {
        title: "IDR List",
        to: "apps-idr-list"
      }
    ]
  }
];
