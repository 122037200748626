const dashboard = [
  {
    path: "/dashboards/analytics",
    name: "dashboard-analytics",
    component: () => import("@/views/dashboards/analytics/Analytics.vue"),
    meta: {
      requiresAuth: true,
      layout: "content"
    }
  }
];

export default dashboard;
