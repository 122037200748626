import { reactive } from "@vue/composition-api";

import AppSnackbar from "./AppSnackbar.vue";

export default AppSnackbar;

function snackbarFactory() {
  return {
    show: false,
    text: "",
    color: "success",
    maxWidth: "250",
    timeout: 3000,
    dark: undefined,
    light: undefined,
    multiLine: true
  };
}

export const snackbarState = reactive(snackbarFactory());

export function snackbar(options) {
  const defaultState = snackbarFactory();
  snackbarState.text = options.text;
  snackbarState.color = options.color || defaultState.color;
  snackbarState.maxWidth = options.maxWidth || defaultState.maxWidth;
  snackbarState.timeout = options.timeout || defaultState.timeout;
  snackbarState.dark = options.dark || defaultState.dark;
  snackbarState.light = options.light || defaultState.light;
  snackbarState.multiLine = options.multiLine || defaultState.multiLine;
  snackbarState.show = true;
}
