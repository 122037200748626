// Extending the Base Class as proposed in https://github.com/feathers-plus/feathers-vuex/pull/216
// causes an error which is described in the issue below.
// A (temporary?) solution is also shown
// https://github.com/feathers-plus/feathers-vuex/issues/254

import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers";

// Extend the base class
class ContractorItem extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "ContractorItem";

  static instanceDefaults() {
    return {
      form_id: null,
      status: null,
      assigned_to: null,
      assigned_to_id: null,
      version: null,
      client_created_at: null,
      created_at: null,
      created_by: null,
      created_by_id: null,
      created_duration: null,
      created_location: null,
      client_updated_at: null,
      updated_at: null,
      updated_by: null,
      updated_by_id: null,
      updated_duration: null,
      updated_location: null,
      edited_duration: null,
      old_item_id: null,
      item_num: null,
      item_desc: null,
      contractor_name: null,
      list_year: null,
      contractor_list_record: null,
      short_list_item_record: null,
      item_cost: null,
      item_comment: null
    };
  }

  // Computed Properties

  // static setupInstance(data) {
  // }
}

const servicePath = "contractor-items";

const servicePlugin = makeServicePlugin({
  debug: true,
  Model: ContractorItem,
  idField: "fulcrum_id",
  service: feathersClient.service(servicePath),
  servicePath
});

// Optionally add service-level hooks here
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
