import { extend } from "vee-validate";
import {
  alpha_num,
  confirmed,
  digits,
  double,
  email,
  max,
  min,
  numeric,
  regex,
  required,
  required_if
} from "vee-validate/dist/rules";

/**
 * Some Useful Regex Patterns
 * Test here: https://regex101.com/
 *
 * All letters in string must be uppercase: ^[A-Z\d]+$
 *
 * Medium password: ^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,}).
 *  - At least one lowercase
 *  - At least one uppercase or one number
 *  - Minimum 6 characters
 *
 *
 * Strong password: ^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})
 *  - At least one lowercase
 *  - At least one uppercase
 *  - At least one numeric
 *  - Minimum 8 characters
 *
 * NIBXXXX Item naming convention: /(NIB\d{4,})/
 *  - Must start with NIB
 *  - NIB must be followed by at least 4 digits
 *
 */

extend("alpha_num", {
  ...alpha_num,
  message: "{_field_} may only contain alpha-numeric characters"
});

extend("confirmed", {
  ...confirmed,
  message: "Passwords do not match"
});

extend("digits", {
  ...digits,
  message: "{_field_} must contain the required number of numbers"
});

extend("double", {
  ...double,
  message: "{_field_} must have a valid decimal value"
});

extend("email", {
  ...email,
  message: "{_field_} must be a valid email address"
});

extend("max", {
  ...max,
  message: "{_field_} may not exceed {length} characters",
  params: ["length"]
});

extend("min", {
  ...min,
  message: "{_field_} must have a value greater than {length}",
  params: ["length"]
});

extend("numeric", {
  ...numeric,
  message: "{_field_} must be a number"
});

extend("regexUppercase", {
  ...regex,
  message: "{_field_} may only contain uppercase characters"
});

extend("regexStrongPassword", {
  validate: (value) => {
    let strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    return strongRegex.test(value);
  },
  message: "{_field_} does not meet the password requirements"
});

extend("regexNIB", {
  ...regex,
  message:
    "Item number must begin with 'NIB' and be followed by at least 4 digits"
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty"
});

extend("required_if", {
  ...required_if,
  message: "{_field_} can not be empty "
});

extend("confirmPassword", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Passwords do not match"
});

extend("preventTempPasswordReuse", {
  params: ["target"],
  validate(value, { target }) {
    return value !== target;
  },
  message: "You cannot reuse a temporary password"
});
