<script>
  import useAppConfig from "@/components/base/app-config/useAppConfig";

  export default {
    setup() {
      const { appContentWidth } = useAppConfig();

      return {
        appContentWidth
      };
    }
  };
</script>

<template>
  <div
    class="app-content-container mx-auto h-full"
    :class="{ 'app-content-container-boxed': appContentWidth === 'boxed' }"
  >
    <slot></slot>
  </div>
</template>

<style lang="scss">
  @import "~@/styles/variables";

  .app-content-container {
    &.app-content-container-boxed {
      max-width: $content-max-width;
    }
  }
</style>
