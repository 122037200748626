// Extending the Base Class as proposed in https://github.com/feathers-plus/feathers-vuex/pull/216
// causes an error which is described in the issue below.
// A (temporary?) solution is also shown
// https://github.com/feathers-plus/feathers-vuex/issues/254

import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers";

// Extend the base class
class ShortListItem extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "ShortListItem";

  static instanceDefaults() {
    return {
      form_id: null,
      status: null,
      assigned_to: null,
      assigned_to_id: null,
      version: null,
      client_created_at: null,
      created_at: null,
      created_by: null,
      created_by_id: null,
      created_duration: null,
      created_location: null,
      client_updated_at: null,
      updated_at: null,
      updated_by: null,
      updated_by_id: null,
      updated_duration: null,
      updated_location: null,
      edited_duration: null,
      old_item_id: null,
      list_year: null,
      short_list_record: null,
      item_number: null,
      item_desc: null,
      item_unit: null,
      item_comment: null,
      category: null,
      item_class: null,
      pif: null,
      sole_source: null,
      supplier: null,
      negotiable: null,
      barcode: null
    };
  }
}

const servicePath = "short-list-items";

const servicePlugin = makeServicePlugin({
  debug: true,
  Model: ShortListItem,
  idField: "fulcrum_id",
  service: feathersClient.service(servicePath),
  servicePath,
  extend: () => {
    return {
      state: {
        pageSkip: 0,
        pageLimit: 25,
        isEnteringText: false,
        filterText: null
      },
      mutations: {
        setFilterTextEnter: (state) => {
          state.isEnteringText = true;
        },
        unsetFilterTextEnter: (state) => {
          state.isEnteringText = false;
        },
        setFilterText: (state, payload) => {
          state.filterText = null;
          state.filterText = payload;
        }
      },
      actions: {
        filterText: (context, payload) => {
          context.commit("setFilterTextEnter");
          context.commit("setFilterText", payload);
          context.commit("unsetFilterTextEnter");
        }
      }
    };
  }
});

// Optionally add service-level hooks here
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [
      (context) => {
        console.log(context.result);
        return context;
      }
    ],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
