// Extending the Base Class as proposed in https://github.com/feathers-plus/feathers-vuex/pull/216
// causes an error which is described in the issue below.
// A (temporary?) solution is also shown
// https://github.com/feathers-plus/feathers-vuex/issues/254

import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers";

// Extend the base class
class ShortList extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "ShortList";

  static instanceDefaults() {
    return {
      form_id: "",
      status: "",
      assigned_to: "",
      assigned_to_id: "",
      version: null,
      client_created_at: "",
      created_at: "",
      created_by: "",
      created_by_id: "",
      created_duration: "",
      created_location: "",
      client_updated_at: "",
      updated_at: "",
      updated_by: "",
      updated_by_id: "",
      updated_duration: "",
      updated_location: "",
      edited_duration: "",
      contract_number: "",
      list_year: "",
      list_date: "",
      contract_record: ""
    };
  }

  // Computed Properties
  // get shortListName() {
  //   return `${this.contract_number} ${this.list_year}`;
  // }

  static setupInstance(data) {
    if (data.contract_number && data.list_year) {
      data.short_list_name = `${data.contract_number} ${data.list_year}`;
    }
  }
}

const servicePath = "short-lists";

const servicePlugin = makeServicePlugin({
  debug: true,
  Model: ShortList,
  idField: "fulcrum_id",
  service: feathersClient.service(servicePath),
  servicePath
});

// Optionally add service-level hooks here
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
