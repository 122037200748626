// Extending the Base Class as proposed in https://github.com/feathers-plus/feathers-vuex/pull/216
// causes an error which is described in the issue below.
// A (temporary?) solution is also shown
// https://github.com/feathers-plus/feathers-vuex/issues/254

import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers";
// import { paramsForServer } from "feathers-hooks-common";

// Extend the base class
class CloseOutLetter extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "CloseOutLetter";
  static instanceDefaults() {
    return {
      fulcrum_id: "",
      proceed_num: "",
      close_out_date: "",
      contract_num: "",
      permit_num: "",
      contractor_name: "",
      address: "",
      city_state: "",
      zip_code: "",
      contractor_poc_name: "",
      owner_name: "",
      complete_date: "",
      status: "",
      proceed_name: "",
      proceed_desc: "",
      ntp_date: "",
      designer_name: "",
      inspector_name: "",
      to_line_email: [],
      cc_line_email: [],
      bcc_line_email: [],
      cc_distro_list: [],
      signatory_name: "",
      signatory_title: "",
      missing_field: []
    };
  }
}

const servicePath = "close-out-letter";

const servicePlugin = makeServicePlugin({
  debug: true,
  Model: CloseOutLetter,
  idField: "fulcrum_id",
  service: feathersClient.service(servicePath),
  servicePath
});

// Optionally add service-level hooks here
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
