import Vue from "vue";
import Vuex from "vuex";

import appConfigStoreModule from "@/components/base/app-config/appConfigStoreModule";

import { FeathersVuex } from "../feathers";
import app from "./app";
import auth from "./auth";

Vue.use(Vuex);
Vue.use(FeathersVuex);

const requireModule = require.context(
  // The path where the service modules live
  "./services",
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /\.js$/
);

const servicePlugins = requireModule
  .keys()
  .map((modulePath) => requireModule(modulePath).default);

export const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app
  },
  plugins: [...servicePlugins, auth]
});
