// Extending the Base Class as proposed in https://github.com/feathers-plus/feathers-vuex/pull/216
// causes an error which is described in the issue below.
// A (temporary?) solution is also shown
// https://github.com/feathers-plus/feathers-vuex/issues/254

import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers";
import hideTestData from "../hooks/hide-test-data";

// Extend the base class
class ConstructionRecord extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "ConstructionRecord";
  static instanceDefaults() {
    return {
      fulcrum_id: null,
      created_duration: null,
      updated_duration: null,
      edited_duration: null,
      created_at: null,
      updated_at: null,
      status: null,
      version: null,
      client_created_at: null,
      client_updated_at: null,
      created_by: null,
      created_by_id: null,
      updated_by: null,
      updated_by_id: null,
      form_id: null,
      project_record: null,
      permit_record: null,
      project_num: null,
      project_name: null,
      permit_num: null,
      permit_desc: null,
      permit_type: null,
      doc_link: null,
      const_comment: null,
      first_idr_date: null,
      last_idr_date: null,
      checklist_record: null,
      project_fulcrum_id: null
    };
  }

  // Computed Properties
  get updatedAt() {
    return this.updated_at.substring(0, this.updated_at.indexOf("T"));
  }

  static setupInstance(data) {
    // `permit_type` is returned as a comma-separated string
    // we only need the first value but we need to send the entire array
    // instead of just the first element
    if (data.permit_type) {
      data.permit_type = data.permit_type.split(",");
    }
  }
}

const servicePath = "construction-records";

const servicePlugin = makeServicePlugin({
  debug: true,
  Model: ConstructionRecord,
  idField: "fulcrum_id",
  service: feathersClient.service(servicePath),
  servicePath,
  state: {
    isSelectingPermit: false,
    isDeselectingPermit: false,
    selectedPermits: [],
    permitIsSelected: false,
    loading: false
  },
  getters: {
    getLoading: (state) => {
      return state.loading;
    }
  },
  mutations: {
    setSelectPermitEnter: (state) => {
      state.isSelectingPermit = true;
    },
    unsetSelectPermitEnter: (state) => {
      state.isSelectingPermit = false;
    },
    setSelectPermit: (state, payload) => {
      state.selectedPermits.push(payload);
    },
    setDeselectPermitEnter: (state) => {
      state.isDeselectingPermit = true;
    },
    unsetDeselectPermitEnter: (state) => {
      state.isDeselectingPermit = false;
    },
    setDeselectPermit: (state, payload) => {
      const index = state.selectedPermits.indexOf(payload);
      if (index > -1) {
        state.selectedPermits.splice(index, 1);
      }
    },
    setRemoveAllPermitsEnter: (state) => {
      state.isRemovingAllPermits = true;
    },
    unsetRemoveAllPermitsEnter: (state) => {
      state.isRemovingAllPermits = false;
    },
    setRemoveAllPermits: (state) => {
      state.selectedPermits = [];
    },
    setLoading: (state, payload) => {
      state.loading = payload;
    }
  },
  actions: {
    selectPermit: (context, payload) => {
      context.commit("setSelectPermitEnter");
      context.commit("setSelectPermit", payload);
      context.commit("unsetSelectPermitEnter");
      console.log("finished selecting a permit");
    },
    deselectPermit: (context, payload) => {
      context.commit("setDeselectPermitEnter");
      context.commit("setDeselectPermit", payload);
      context.commit("unsetDeselectPermitEnter");
    },
    removeAllPermits: (context) => {
      context.commit("setRemoveAllPermitsEnter");
      context.commit("setRemoveAllPermits");
      context.commit("unsetRemoveAllPermitsEnter");
    },
    updateLoading: (context, payload) => {
      context.commit("setLoading", payload);
    }
  },
  handleEvents: {
    created: (item) => {
      console.log("Greetings from the created event!");
      console.log(`${item} created in database`);
    },
    patched: (item) => {
      console.log("Greetings from the patched event!");
      console.log(`${item} patched in database`);
    },
    updated: (item) => {
      console.log("Greetings from the updated event!");
      console.log(`${item} updated in database`);
    },
    removed: (item, { model }) => {
      console.log(`${item} removed from database`);
      console.log(model);
    }
  }
});

// Optionally add service-level hooks here
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [hideTestData()],
    get: [],
    create: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [
      // (context) => {
      //   let data = context.result.data;
      //   data.forEach(function (data) {
      //     if (data.permit_type !== null) {
      //       data.permit_type = data.permit_type.split(",");
      //     }
      //   });
      //   return context;
      // }
    ],
    get: [],
    create: [
      (context) => {
        console.log("This is after create");
        console.log(context);
        return context;
      }
    ],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
