// Extending the Base Class as proposed in https://github.com/feathers-plus/feathers-vuex/pull/216
// causes an error which is described in the issue below.
// A (temporary?) solution is also shown
// https://github.com/feathers-plus/feathers-vuex/issues/254

import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers";

// Extend the base class
class User extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "User";

  static instanceDefaults() {
    return {
      contactId: null,
      email: null,
      password: null,
      phone: null,
      isVerified: false,
      active: true,
      roleId: null,
      orgId: null,
      // verifyToken: "",
      // verifyExpires: "",
      // verifyChanges: "",
      // resetToken: "",
      // resetShortToken: "",
      // resetExpires: "",
      // createdAt: new Date(),
      // updatedAt: new Date(),
      // deletedAt: "",
      newPassword: null
      //accounts: "Account"
    };
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  get userInitials() {
    return `${this.firstName.substring(0, 1)}${this.lastName.substring(0, 1)}`;
  }

  // get formattedCreatedAtDate() {
  //   var dateAsString = this.createdAt.toString();
  //   return new Date(dateAsString).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "2-digit"});
  // }

  // get formattedCreatedAtDate() {
  //   var dateAsString = this.createdAt.toString();
  //   return new Date(dateAsString).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "2-digit"});
  // }

  static setupInstance(data) {
    if (data.active && data.isVerified == true) {
      data.status = "Active";
    } else if (data.active == false) {
      data.status = "Inactive";
    } else if (data.verifyToken !== null) {
      data.status = "Pending";
    }
  }
}

const servicePath = "users";

const servicePlugin = makeServicePlugin({
  debug: true,
  Model: User,
  service: feathersClient.service(servicePath),
  servicePath,
  enableEvents: true,
  handleEvents: {},
  state: {
    pageSkip: 0,
    pageLimit: 50,
    sortField: null,
    sortOrder: 1,
    isUpdatingPagination: false,
    isEnteringText: false,
    searchText: "",
    isAddingFilter: false,
    isRemovingFilter: false,
    activeFilters: [],
    filterText: null
  },
  mutations: {
    setIsAddingFilter: (state) => {
      state.isAddingFilter = true;
    },
    unsetIsAddingFilter: (state) => {
      state.isAddingFilter = false;
    },
    setIsRemovingFilter: (state) => {
      state.isRemovingFilter = true;
    },
    unsetIsRemovingFilter: (state) => {
      state.isRemovingFilter = false;
    },
    setUpdatePaginationEnter: (state) => {
      state.isUpdatingPagination = true;
    },
    unsetUpdatePaginationEnter: (state) => {
      state.isUpdatingPagination = false;
    },
    setUpdatePagination: (state, payload) => {
      state.sortField = null;
      state.pageSkip = (payload.page - 1) * state.pageLimit;
      state.pageLimit = payload.itemsPerPage;
      state.sortField = payload.sortBy.length > 0 ? payload.sortBy[0] : null;
      state.sortDesc = payload.sortDesc[0] == true ? -1 : 1;
    },
    setFilterTextEnter: (state) => {
      state.isFilteringText = true;
    },
    unsetFilterTextEnter: (state) => {
      state.isFilteringText = false;
    },
    setFilterText: (state, payload) => {
      state.filterText = null;
      state.filterText = payload;
      console.log(state.filterText);
    },
    setClearAllFilters: (state) => {
      state.filterText = null;
    }
  },
  actions: {
    updatePagination: (context, payload) => {
      context.commit("setUpdatePaginationEnter");
      context.commit("setUpdatePagination", payload);
      context.commit("unsetUpdatePaginationEnter");
    },
    filterText: (context, payload) => {
      context.commit("setFilterTextEnter");
      context.commit("setFilterText", payload);
      context.commit("unsetFilterTextEnter");
    },
    clearAllFilters: (context) => {
      context.commit("setClearAllFilters");
    }
  }
  // Set up a (1:1) relationship between Accounts and Users
  //setupInstance
});

// Optionally add service-level hooks here
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
