// Extending the Base Class as proposed in https://github.com/feathers-plus/feathers-vuex/pull/216
// causes an error which is described in the issue below.
// A (temporary?) solution is also shown
// https://github.com/feathers-plus/feathers-vuex/issues/254

import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers";
// import { paramsForServer } from "feathers-hooks-common";

// Extend the base class
class PressRelease extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "PressRelease";
  static instanceDefaults() {
    return {
      fulcrum_id: null,
      proceed_name: null,
      project_type: null,
      contractor_name: null,
      estimate_start_date: null,
      rain_start_date: null,
      work_period: null,
      mot_req: null,
      day_start_time: null,
      day_end_time: null,
      night_start_time: null,
      night_end_time: null,
      short_work_desc: null,
      duration_days: null,
      contract_num: null,
      county_name: null,
      inspector_name: null,
      inspector_phone: null,
      design_desc: null,
      to_line_email: [],
      cc_line_email: [],
      bcc_line_email: [],
      missing_field: []
    };
  }
}

const servicePath = "press-release";

const servicePlugin = makeServicePlugin({
  debug: true,
  Model: PressRelease,
  idField: "fulcrum_id",
  service: feathersClient.service(servicePath),
  servicePath
});

// Optionally add service-level hooks here
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
