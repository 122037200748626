<script>
  import {
    mdiAccountOutline,
    mdiCheckboxMarkedOutline,
    mdiCogOutline,
    mdiEmailOutline,
    mdiGiftOutline,
    mdiHelpCircleOutline,
    mdiLogoutVariant
  } from "@mdi/js";
  import { computed } from "@vue/composition-api";

  import { useRouter } from "@/helpers";
  import { store } from "@/store";

  export default {
    setup() {
      //const vm = getCurrentInstance().proxy
      const { router } = useRouter();
      const authUser = computed(() => store.state.auth.user);

      const logoutUser = async () => {
        let findUserProfileParams = {
          query: {
            $limit: 1,
            userId: store.state.auth.user.id
          }
        };
        let patchData = {
          isOnline: false
        };
        let userProfileId = (
          await store.dispatch("profiles/find", findUserProfileParams)
        ).data[0].id;

        await store.dispatch("profiles/patch", [userProfileId, patchData]);
        store.dispatch("auth/logout").then(() => {
          // Redirect to login page
          router.push({ name: "account-login" });
        });
      };

      return {
        logoutUser,
        authUser,

        icons: {
          mdiAccountOutline,
          mdiCheckboxMarkedOutline,
          mdiCogOutline,
          mdiEmailOutline,
          mdiGiftOutline,
          mdiHelpCircleOutline,
          mdiLogoutVariant
        }
      };
    }
  };
</script>

<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        :color="
          authUser && authUser.isOnline && authUser.isVisible
            ? 'success'
            : 'default'
        "
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <v-img
            v-if="authUser && authUser.avatar"
            :src="authUser.avatar"
          ></v-img>
          <v-icon v-else color="primary" size="28">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          :color="
            authUser && authUser.isOnline && authUser.isVisible
              ? 'success'
              : 'default'
          "
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img
              v-if="authUser && authUser.avatar"
              :src="authUser.avatar"
            ></v-img>
            <v-icon v-else color="primary" size="28">
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-avatar>
        </v-badge>
        <div
          v-if="authUser"
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align: middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ authUser.firstName }} {{ authUser.lastName }}
          </span>
          <small class="text--disabled text-capitalize">{{
            authUser.role
          }}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Profile -->
      <v-list-item
        v-if="authUser"
        :to="{ name: 'apps-user-view', params: { id: authUser.id } }"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Settings -->
      <!--<v-list-item
        :to="{
          name: 'apps-user-account-settings',
          params: { id: authUser.id }
        }"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item>-->

      <!-- FAQ -->
      <v-list-item :to="{ name: 'whats-new' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiGiftOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>What's New</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        href="mailto:tpm_admin@centurygeo.com?subject=TPM Help Request"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiEmailOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Help</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style lang="scss">
  .user-profile-menu-content {
    .v-list-item {
      min-height: 2.5rem !important;
    }
  }
</style>
