<script>
  import LayoutBlank from "@/components/base/layouts/blank/LayoutBlank.vue";

  export default {
    components: {
      LayoutBlank
    }
  };
</script>

<template>
  <layout-blank>
    <slot></slot>
  </layout-blank>
</template>

<style></style>
