const account = [
  {
    path: "/account/login",
    name: "account-login",
    component: () => import("@/views/account/Login.vue"),
    meta: { requiresAuth: false, layout: "blank" }
  },
  {
    path: "/account/forgot-password",
    name: "account-forgot-password",
    component: () => import("@/views/account/ForgotPassword.vue"),
    meta: { requiresAuth: false, layout: "blank" }
  },
  {
    path: "/account/verify",
    name: "account-register",
    props: (route) => ({ email: route.query.email, token: route.query.token }),
    component: () => import("@/views/account/Register.vue"),
    meta: { requiresAuth: false, layout: "blank" },
    beforeEnter: (to, from, next) => {
      const hasEmail = to.query.email ? true : false;
      const hasToken = to.query.token ? true : false;
      if (hasToken && hasEmail) {
        // Allow the user to proceed if there's an email address
        // and a token in the URL
        return next();
      } else {
        return next("/account/login");
      }
    }
  },
  {
    path: "/account/reset",
    name: "account-reset-password",
    component: () => import("@/views/account/ResetPassword.vue"),
    meta: { requiresAuth: false, layout: "blank" },
    beforeEnter: (to, from, next) => {
      const hasToken = to.query.token ? true : false;
      if (hasToken) {
        // Allow the user to proceed if there is a token in the URL
        return next();
      } else {
        return next("/account/login");
      }
    },
    props: (route) => ({ token: route.query.token })
  }
];

export default account;
