import { mdiAccountOutline } from "@mdi/js";

export default [
  {
    subheader: "USERS"
  },
  {
    title: "Users",
    icon: mdiAccountOutline,
    children: [
      {
        title: "User List",
        to: "apps-user-list"
      }
    ]
  }
];
