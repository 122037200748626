// Extending the Base Class as proposed in https://github.com/feathers-plus/feathers-vuex/pull/216
// causes an error which is described in the issue below.
// A (temporary?) solution is also shown
// https://github.com/feathers-plus/feathers-vuex/issues/254
import { store } from "@/store";

import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers";
//import hideTestData from '../hooks/hide-test-data';

// Extend the base class
class DailyLogInvoice extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "DailyLogInvoice";
  static idField = "fulcrum_id";
  static instanceDefaults() {
    return {
      fulcrum_id: null,
      invoiced_on: null,
      invoiced_by: null
    };
  }
}

const servicePath = "daily-log-invoices";

const servicePlugin = makeServicePlugin({
  Model: DailyLogInvoice,
  debug: true,
  idField: "fulcrum_id",
  service: feathersClient.service(servicePath),
  servicePath,
  extend: () => {
    return {
      state: {
        loading: false
      },
      mutations: {
        setLoading: (state, payload) => {
          state.loading = payload;
        }
      },
      actions: {
        updateLoading: (context, payload) => {
          context.commit("setLoading", payload);
        }
      },
      handleEvents: {
        patched: (item) => {
          console.log("Greetings from the patched event!");
          store.dispatch("daily-log-invoices/updateLoading", false);
          console.log(`${item} patched in database`);
        }
      }
    };
  }
});

// Optionally add service-level hooks here
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
