const pages = [
  {
    path: "/pages/whats-new",
    name: "whats-new",
    component: () => import("@/views/pages/WhatsNew.vue"),
    meta: {
      requiresAuth: true,
      layout: "content"
    }
  }
];

export default pages;
