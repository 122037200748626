// Extending the Base Class as proposed in https://github.com/feathers-plus/feathers-vuex/pull/216
// causes an error which is described in the issue below.
// A (temporary?) solution is also shown
// https://github.com/feathers-plus/feathers-vuex/issues/254

import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers";

// Extend the base class
class Contact extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Contact";

  static instanceDefaults() {
    return {
      fulcrum_id: "",
      form_id: "",
      status: "",
      assigned_to: "",
      assigned_to_id: "",
      version: "",
      client_created_at: "",
      created_at: "",
      created_by: "",
      created_by_id: "",
      created_duration: "",
      created_location: "",
      client_updated_at: "",
      updated_at: "",
      updated_by: "",
      updated_by_id: "",
      updated_duration: "",
      updated_location: "",
      edited_duration: "",
      calc_title: "",
      org_id: "",
      org_name: "",
      org_record: "",
      contact_type: "",
      contact_role: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      contact_position: "",
      department: "",
      comment: "",
      phone_office: "",
      phone_office2: "",
      phone_cell: "",
      phone_fax: "",
      email: "",
      address: "",
      city: "",
      state: "",
      zip_code: "",
      admin_user: "",
      admin_persona: "",
      utility_locator: "",
      udo_inspector: "",
      udo_reviewer: "",
      tst_manager: "",
      tpm_permit_inspector: "",
      tpm_permit_manager: "",
      tpm_const_inspector: "",
      tpm_const_manager: "",
      tpm_designer: "",
      tpm_receive_ntp: "",
      tpm_cc_order_ntp: "",
      tpm_receive_pr: "",
      tpm_receive_close_out: "",
      tpm_cc_order_close_out: ""
    };
  }

  static setupInstance(data) {
    // return contact's full name
    if (data.first_name && data.last_name) {
      data.full_name = `${data.first_name} ${data.last_name}`;
    }
  }
}

const servicePath = "contacts";

const servicePlugin = makeServicePlugin({
  debug: true,
  Model: Contact,
  idField: "fulcrum_id",
  service: feathersClient.service(servicePath),
  servicePath
});

// Optionally add service-level hooks here
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
