export default function hideTestData() {
  return async (context) => {
    if (process.env.VUE_APP_ENV == "production") {
      context.params.query = {
        ...context.params.query,
        project_fulcrum_id: {
          $or: [
            {
              $eq: null
            },
            {
              $ne: "ff13dbd2-64ef-4bcd-bfd7-5ff8af92d038"
            }
          ]
        }
      };
      return context;
    }
  };
}
